import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Stack } from 'react-bootstrap';
import { SlashCircle } from 'react-bootstrap-icons';
import { ACRemoteButtons, RemoteButtons, tempButton } from '../utlity/Remote';
import './RemoteSchedule.css'
import { notifyFailed } from '../utlity/Notification';
import { fetchSchedule, fetchSchedules, postSchedule } from '../Redux/Action/ScheduleAction';
import AlertSuccessModel from '../Model/AlertSuccessModel';

function RemoteSchedule({ remoteData, house, roomId, deviceId }) {
    const [btn,setbtn] = useState(false)
    const [timeInterval, setTimeInterval] = useState(1)
    const [scheduleName,setScheduleName] = useState('')
    const [successModel,setSuccessModel] = useState(false)

    const [state, setState] = useState([])
    const temKeys = Object.keys(tempButton)
    let remote = remoteData['custom_remotes'][0]

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        fetchDataSchedule()
    },[])

    const handleCheck = (checked, data) => {
        if (checked) {
            setState([data])
        } else {
            setState([])
        }
    }


    function calculateRestIntervals(startTime, restIntervalMinutes) {
        const restIntervals = [];
        let currentTime = new Date(startTime);
        const endOfDay = new Date(currentTime);
        endOfDay.setHours(23, 59, 59, 999); // Setting end of day to 11:59:59 PM

        while (currentTime < endOfDay) {
            const restEndTime = new Date(currentTime.getTime() + restIntervalMinutes * 60000); // Adding milliseconds equivalent to minutes
            restIntervals.push([new Date(currentTime), restEndTime]);
            currentTime = new Date(restEndTime);
        }

        return restIntervals;
    }

    const makeScheduleData = async() => {
        setbtn(true)
        let res = calculateRestIntervals(new Date().setHours(0, 0, 0, 0), timeInterval)
        let scheduleData = []
        for (let interval of res) {
            let data = {
                "house": house._id,
                "name": scheduleName,
                "time": interval[0],
                "day": [
                    0,
                    1,
                    2,
                    3,
                    4,
                    5,
                    6
                ],
                "states": [
                    {
                        "deviceId": deviceId,
                        "type": "nova",
                        "roomId": roomId,
                        "buttons": state,
                        "remoteId": remote._id
                    }
                ],
                "repeat": "every",
                "offset": new Date().getTimezoneOffset()
            }
            scheduleData.push(data)
        }
      
        let promise = []
        for(let s of scheduleData){
            promise.push(postSchedule(s))
        }
       await Promise.all(promise).then(ress=>{
           setSuccessModel(true)
        }).catch(err=>{
      
        })
       await fetchDataSchedule()
       handleClose()
        setbtn(false)
    }
    const fetchDataSchedule = async()=>{
        let fetchData = []
        fetchData.push(fetchSchedules(house._id))
       await Promise.all(fetchData).then(res=>{
            setbtn(false)
        }).catch(function (err) {
            setbtn(false)
        })
    }
    return (
        <>
            <Button
                variant="outline-secondary"
                size='sm'
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
                onClick={handleShow}
            >
                <SlashCircle style={{ marginRight: "5px" }} />
                Remote Schedule
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-5'>
                    <Form.Label>Remote Name :- {remote.name}</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">


                        <Form.Label>Schedule Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="name"
                            value={scheduleName}
                            onChange={(e) => {
                                 setScheduleName(e.target.value)
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">


                        <Form.Label>Time Interval (minutes)</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="intemal"
                            max={"59"} min={"1"}
                            value={timeInterval}
                            onChange={(e) => {
                                if (Number(e.target.value) >= 1) {
                                    setTimeInterval(Number(e.target.value))
                                }
                            }}
                        />
                    </Form.Group>
                    <Stack>
                        {
                            temKeys.map((tem) => {
                                let b = ACRemoteButtons[tem]
                                let data = remote.buttons.find(r => b.label === r.name)
                                return (
                                    <div className='command-label-container'>
                                        <div className='command-container'>
                                            <div>
                                                <img
                                                    src={b.image}
                                                    className='temp-label-img'
                                                />
                                            </div>

                                            <div className='temp-label'>{b.label}</div>
                                        </div>
                                        <Form.Check // prettier-ignore
                                            type='radio'
                                            name={'temm'}
                                            label={""}
                                            onChange={(e) => handleCheck(e.target.checked, data)}
                                        />
                                    </div>
                                )


                            })
                        }
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            if (state.length === 0) {
                                notifyFailed("command not selected")
                                return
                            }
                            if(scheduleName===""){
                                notifyFailed("Please Enter Schedule Name")
                                return
                            }
                            makeScheduleData()
                        }}
                        disabled={btn}
                    >
                        Submit
                    </Button>

                </Modal.Footer>
            </Modal>
            <AlertSuccessModel
                open={successModel}
                setOpen={setSuccessModel}
            />
        </>
    )
}

export default RemoteSchedule