import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ScheduleEdit from './ScheduleEdit'
import moment from 'moment'
import {Trash3Fill} from 'react-bootstrap-icons'
import { deleteSchedule } from '../Redux/Action/ScheduleAction'
import { Button, Modal } from 'react-bootstrap'


export default function FetchScheduleRow({deviceId, switchId}) {
    const schedule = useSelector((state)=>state.schedule)
    console.log(schedule,"schedule");
    let [show,setShow] = useState(false)
    let [confirmationShow,setConfirmationShow] = useState(false)
    let [selectedSchedule,setSelectedSchedule] = useState({})
    let [schedulePage,setSchedulePage] = useState([])
    let [btn,setBtn] = useState("Yes")
    const confirmHandleClose = () => {
      setConfirmationShow(false)
      setSelectedSchedule({})
    };
    const confirmHandleShow = (s) => {
      setConfirmationShow(true)
      setSelectedSchedule(s)
    
    };
    useEffect(()=>{
        let scheduleArray = []
        if(schedule.length===0) {setSchedulePage([])}
        for(let sched of schedule){
        if(Object.keys(sched).length!==0 && sched.success===true && sched.schedule.length!==0){
          for(let s of sched.schedule){
              for(let check of s.states){
                  if(deviceId===check.deviceId && switchId===check.switchId){  
                      scheduleArray.push({...check,...s,scheduleId:s._id})
                  }
              }
          }
          setSchedulePage(scheduleArray)
      }
    }
      
        
    },[schedule])
    useEffect(()=>{
      console.log(selectedSchedule,"loggg");
    },[selectedSchedule])
    
  return (
    <>
    {/* <Toaster position="top-center" /> */}
    <ul style={{textAlign:"left"}}>
       {
        schedulePage.length!==0?
          schedulePage.map((s,i)=>{
            console.log(s,"sssss")
             return(
                <div style={{display:"flex",gap:15}} key={i+"schedule"}>
                <li onClick={()=>{
                  if(s.switchState!=="0"){
                  setSelectedSchedule(s)
                                  setShow(true)}}}
                    style={{cursor:s.switchState!=="0"?"pointer":"",color:"#1E1EBB"}}
                >{s.name} - {s.type==="nova"?`${s.states[0].buttons[0].name} temperature`:`Turn ${s.switchState=="0"?"Off":"On"}`} - {moment(new Date(s.time)).format('LT')} 
                </li>
                <Trash3Fill onClick={()=>confirmHandleShow(s)}/>
                </div>
             )
          },[]):<div>No Schedule Set</div>
       }
    </ul>
    {/* <ScheduleEdit
      show={show}
      onHide={() => setShow(false)}
      selectedSchedule={selectedSchedule}
      schedulePage={schedulePage}
      setSelectedSchedule={setSelectedSchedule}
    /> */}
    <Modal show={confirmationShow} onHide={confirmHandleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to Delete {selectedSchedule.name} Schedules ?.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={confirmHandleClose}>
          Close
        </Button>
        <Button variant="primary" style={{pointerEvents:btn==="Loading..."?"none":""}} onClick={()=>{
          setConfirmationShow(false)
          deleteSchedule(selectedSchedule._id)}}>
          {btn}
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}
