import axios from "axios"
import { serverUrl } from "../../../ApiUri"
import { notifyFailed } from "../../utlity/Notification";



export const controlRemoteCommand  = (details)=>{
    
    axios.post(`${serverUrl.a2}/v2/nova/control`,details).then(function (res) {
        console.log('====================================');
        console.log(res);
        if(res.data.success){

        }else{
            notifyFailed(res.data.message)
        }
        console.log('====================================');
    }).catch(function (err) {
        console.log('====================================');
        console.log(err);
        notifyFailed(err.response.data.message)
        console.log('====================================');
    })
}