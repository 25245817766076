import React from 'react'
import "./Header.css"
import { useSelector } from 'react-redux'

export default function Header() {
  const user = useSelector(state => state.User)
  return (
    <div
      style={{
        width: "100%",
        height: "10vh",
        backgroundColor: "#FFFFFF",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 5%"
      }}
    >
      <div>
        <span className='clientHeader'>Chhavi </span>
        <span className='clientSubHeader'>Hotels</span>
      </div>
      <div style={{
        display: "flex"
      }}>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "10px",
            justifyContent: "center",
            gap: "10px"
          }}
        >
          <span className='userName'>{user.userName}</span>
          <span className='userName'>v 1.00</span>
          {/* <span className='admin'>admin</span> */}
        </div>
      </div>

    </div>
  )
}
