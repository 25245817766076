import axios from "axios"
import { schedule } from "./ActionTypes"
import { serverUrl } from "../../../ApiUri"
import store from "../index"
import { notifyFailed, notifySuccess } from "../../utlity/Notification"


export const scheduleFetch = (data) => {
  return {
    type: schedule.FETCH_SCHEDULE,
    payload: data
  }

}

export const scheduleAdd = (data) => {
  return {
    type: schedule.ADD_SINGLE_SCHEDULE,
    payload: data
  }
}

export const emptySchedule = (data) => {
  return {
    type: schedule.EMPTY_SCHEDULE,
    payload: []
  }
}

export const removeSchedule = (data) => {
  return {
    type: schedule.REMOVE_SCHEDULE,
    payload: data
  }
}
export const removeMultpleSchedule = (data)=>{
  return {
    type: schedule.REMOVE_MULTPLESCHEDULE,
    payload: data
  }
}


export const AddSchedule = async (details) => {

  await axios.post(
    `${serverUrl.main}/app/stanza/addSchedule`,
    details,
  ).then(function (res) {

    if (res.data.success === true) {
      notifySuccess(res.data.message)
    } else {
      notifyFailed(res.data.message)
    }

  }).catch(function (err) {
    console.log(err);

  })

}

export const fetchSchedule = async () => {
return
  let SchedulesData = []
  for (let house of store.getState().HouseDetails) {

    if (house.success === true) {
     await fetchSingleSchedule(house.house._id)
    }
  }

}
export const fetchSingleSchedule = async (houseid="64ba41ca74eca9173f693cbc") => {
  let response = await axios.post(
    `${serverUrl.main}/app/stanza/fetchSchedules`, {
    houseId: houseid
  }
  ).then(function (res) {
    if (res.data.success === true) {
      if (res.data.data !== undefined) {
        store.dispatch(scheduleAdd({ success: true, schedule: res.data.data,id:houseid  }))
      }
    } else {
      notifyFailed(res.data.message)
    }
  }).catch(function (err) {
    console.log(err);
  })
  return response;
}

export const editSchedule = async (scheduleId, details, offtime) => {

  return await axios.post(
    `${serverUrl.main}/app/stanza/editSchedule`, {
    details,
    id: scheduleId,
    user: store.getState().User.userName,
    offtime,
    userName: store.getState().User.userName
  },
  ).then(function (res) {

    if (res.data.success === true) {
      if (res.data.data !== undefined && res.data.data.success === true) {
        notifySuccess(res.data.data.messagae)
      }
      if (res.data.data !== undefined && res.data.data.success === false) {
        notifyFailed(res.data.data.messagae)
      }
      notifySuccess(res.data.messagae)
      
    } else {
      notifyFailed(res.data.messagae)
    }
    return res.data
  }).catch(function (err) {
    console.log(err);
    return {
      success: false,
      message: "Failed"
    }
  })
  
}

// export const deleteSchedule = async (schedule) => {

  
//   let data = [];
//   for (let s of store.getState().schedule) {
//     for (let i of s.schedule) {
      
//       if (i.mainId === schedule.mainId) {
//         data.push(i)
//       }
//     }
//   }
//   let promise = []
//   let scheduleRemove = []
//   var count = 0
//   for (let d of data) {
//     scheduleRemove.push(d.mainId)
//      promise.push(deleteSingleSchedule(d,count))
//   }
//    await Promise.all(promise)
//    console.log(scheduleRemove,"remove");
//   store.dispatch(removeMultpleSchedule(scheduleRemove))
//   fetchSingleSchedule()
//   notifySuccess(`${data.length} Schedules Deleted`)
  
// }

// const deleteSingleSchedule = async (d,count) => {
//   await axios.delete(`${serverUrl.main}/app/stanza/deleteSchedule`, {
//     data: {
//       "id": d.mainId,
//       "user": store.getState().User.userName,
//     }
//   },).then(function (res) {
//     if (res.data.success === true) {
//       console.log("come",res);
//       count=count+1
//       // store.dispatch(removeSchedule(d.mainId))
//       if (res.data.data !== undefined && res.data.data.success === false) {
//       }
    
//     } else {

//     }
//   })
//     .catch(error => {
//       console.log(error.message);

//     });
// }

export const postSchedule = details  => {

  return new Promise(resolve => {
    axios.post(
      `${serverUrl.main}/v2/schedule/create?user=${
        "+918708923677"
      }&time=${new Date().getTime()}`,
      details,
    )
      .then(
        result => {
          const {data, success, message} = result.data;
          // dispatch(add(data.schedule));
          resolve({success, message});
        },
        error => {
          var message = error.message;
          if (error.response && error.response.data) {
            message = error.response.data.message;
          }
          throw new Error(message);
        },
      )
      .catch(error => {
        // dispatch(errored(error.message));
        resolve({success: false, message: error.message});
      });
  });
};

export const fetchSchedules = houseId => {
   console.log('====================================');
   console.log("new");
   console.log('====================================');
  return new Promise(resolve => {
    axios.get(
      `${serverUrl.main}/v2/schedule/list/${houseId}?user=${
        "+91870892367"
      }&time=${new Date().getTime()}`,
    )
      .then(
        result => {
          const {success, message, data} = result.data;
          console.log(data,"ddddd");
          store.dispatch(scheduleAdd({ success: true, schedule: data.schedules,id:houseId  }))

          resolve({success, message});
        },
        error => {
          var message = error.message;
          if (error.response && error.response.data) {
            message = error.response.data.message;
          }
          throw new Error(message);
        },
      )
      .catch(error => {
        
        resolve({success: false, message: error.message});
      });
  });
};

export const deleteSchedule = schedule_id => {
  return new Promise(resolve => {
    axios.delete(
      `${serverUrl.main}/v2/schedule/${schedule_id}?user=${
        "+918708923677"
      }&time=${new Date().getTime()}`,
    )
      .then(
        result => {
          const {data, success, message} = result.data;
          store.dispatch(removeSchedule(schedule_id))
          resolve({success, message});
        },
        error => {
          var message = error.message;
          if (error.response && error.response.data) {
            message = error.response.data.message;
          }
          throw new Error(message);
        },
      )
      .catch(error => {
        
        resolve({success: false, message: error.message});
      });
  });
};