export const RemoteButtons = {
    NONE: {
      value: -1,
      label: '',
      image: require('../../assests/ir/plus.png'),
      tintColor: 'grey',
    },
    OTHER: {value: 0, label: 'Other', image: null, tintColor: undefined},
    alexa: {
      value: 1,
      label: 'Alexa',
      image: require('../../assests/ir/alexa.png'),
      tintColor: undefined,
    },
    blue: {
      value: 2,
      label: 'Blue',
      image: require('../../assests/ir/blue.png'),
      tintColor: undefined,
    },
    circle: {
      value: 3,
      label: 'Circle',
      image: require('../../assests/ir/circle.png'),
      tintColor: 'white',
    },
    down: {
      value: 4,
      label: 'Down',
      image: require('../../assests/ir/down.png'),
      tintColor: 'white',
    },
    eight: {
      value: 5,
      label: 'Eight',
      image: require('../../assests/ir/eight.png'),
      tintColor: 'white',
    },
    fan: {
      value: 6,
      label: 'Fan',
      image: require('../../assests/ir/fan.png'),
      tintColor: 'white',
    },
    fastforward: {
      value: 7,
      label: 'Fast Forward',
      image: require('../../assests/ir/fastforward.png'),
      tintColor: 'white',
    },
    five: {
      value: 8,
      label: 'Five',
      image: require('../../assests/ir/five.png'),
      tintColor: 'white',
    },
    four: {
      value: 9,
      label: 'Four',
      image: require('../../assests/ir/four.png'),
      tintColor: 'white',
    },
    gnow: {
      value: 10,
      label: 'Google Asst.',
      image: require('../../assests/ir/gnow.png'),
      tintColor: undefined,
    },
    green: {
      value: 11,
      label: 'Green',
      image: require('../../assests/ir/green.png'),
      tintColor: undefined,
    },
    home: {
      value: 12,
      label: 'Home',
      image: require('../../assests/ir/home.png'),
      tintColor: 'white',
    },
    hotstar: {
      value: 13,
      label: 'Hotstar',
      image: require('../../assests/ir/hotstar.png'),
      tintColor: undefined,
    },
    left: {
      value: 14,
      label: 'Left',
      image: require('../../assests/ir/left.png'),
      tintColor: 'white',
    },
    menu: {
      value: 15,
      label: 'Menu',
      image: require('../../assests/ir/menu.png'),
      tintColor: 'white',
    },
    minus: {
      value: 16,
      label: 'Minus',
      image: require('../../assests/ir/minus.png'),
      tintColor: 'white',
    },
    mute: {
      value: 17,
      label: 'Mute',
      image: require('../../assests/ir/mute.png'),
      tintColor: 'white',
    },
    netflix: {
      value: 18,
      label: 'Netflix',
      image: require('../../assests/ir/netflix.png'),
      tintColor: undefined,
    },
    nine: {
      value: 19,
      label: 'Nine',
      image: require('../../assests/ir/nine.png'),
      tintColor: 'white',
    },
    one: {
      value: 20,
      label: 'One',
      image: require('../../assests/ir/one.png'),
      tintColor: 'white',
    },
    playpause: {
      value: 21,
      label: 'Play Pause',
      image: require('../../assests/ir/playpause.png'),
      tintColor: 'white',
    },
    plus: {
      value: 22,
      label: 'Plus',
      image: require('../../assests/ir/plus.png'),
      tintColor: 'white',
    },
    power: {
      value: 23,
      label: 'Power',
      image: require('../../assests/ir/power.png'),
      tintColor: 'white',
    },
    power_on: {
      value: 24,
      label: 'Power On',
      image: require('../../assests/ir/power.png'),
      tintColor: 'white',
    },
    power_off: {
      value: 25,
      label: 'Power Off',
      image: require('../../assests/ir/power.png'),
      tintColor: 'white',
    },
    prime: {
      value: 26,
      label: 'Prime',
      image: require('../../assests/ir/prime.png'),
      tintColor: undefined,
    },
    red: {
      value: 27,
      label: 'Red',
      image: require('../../assests/ir/red.png'),
      tintColor: undefined,
    },
    rewind: {
      value: 28,
      label: 'Rewind',
      image: require('../../assests/ir/rewind.png'),
      tintColor: 'white',
    },
    right: {
      value: 29,
      label: 'Right',
      image: require('../../assests/ir/right.png'),
      tintColor: 'white',
    },
    settings: {
      value: 30,
      label: 'Settings',
      image: require('../../assests/ir/settings.png'),
      tintColor: 'white',
    },
    seven: {
      value: 31,
      label: 'Seven',
      image: require('../../assests/ir/seven.png'),
      tintColor: 'white',
    },
    six: {
      value: 32,
      label: 'Six',
      image: require('../../assests/ir/six.png'),
      tintColor: 'white',
    },
    source: {
      value: 33,
      label: 'Source',
      image: require('../../assests/ir/source.png'),
      tintColor: 'white',
    },
    swing: {
      value: 34,
      label: 'Swing',
      image: require('../../assests/ir/swing.png'),
      tintColor: 'white',
    },
    three: {
      value: 35,
      label: 'Three',
      image: require('../../assests/ir/three.png'),
      tintColor: 'white',
    },
    turbo: {
      value: 36,
      label: 'Turbo',
      image: require('../../assests/ir/turbo.png'),
      tintColor: 'white',
    },
    two: {
      value: 37,
      label: 'Two',
      image: require('../../assests/ir/two.png'),
      tintColor: 'white',
    },
    up: {
      value: 38,
      label: 'Up',
      image: require('../../assests/ir/up.png'),
      tintColor: 'white',
    },
    yellow: {
      value: 39,
      label: 'Yellow',
      image: require('../../assests/ir/yellow.png'),
      tintColor: undefined,
    },
    youtube: {
      value: 40,
      label: 'YouTube',
      image: require('../../assests/ir/youtube.png'),
      tintColor: undefined,
    },
    zero: {
      value: 41,
      label: 'Zero',
      image: require('../../assests/ir/zero.png'),
      tintColor: 'white',
    },
    channel_plus: {
      value: 42,
      label: 'Channel +',
      image: require('../../assests/ir/plus.png'),
      tintColor: 'white',
    },
    volume_plus: {
      value: 43,
      label: 'Volume +',
      image: require('../../assests/ir/plus.png'),
      tintColor: 'white',
    },
    channel_minus: {
      value: 44,
      label: 'Channel -',
      image: require('../../assests/ir/minus.png'),
      tintColor: 'white',
    },
    volume_minus: {
      value: 45,
      label: 'Volume -',
      image: require('../../assests/ir/minus.png'),
      tintColor: 'white',
    },
    center: {
      value: 46,
      label: 'Center',
      image: require('../../assests/ir/circle.png'),
      tintColor: 'white',
    },
    sixteen: {
      value: 47,
      label: 'Sixteen',
      image: require('../../assests/ir/16.png'),
      tintColor: 'white',
    },
    seventeen: {
      value: 48,
      label: 'Seventeen',
      image: require('../../assests/ir/17.png'),
      tintColor: 'white',
    },
    eighteen: {
      value: 49,
      label: 'Eighteen',
      image: require('../../assests/ir/18.png'),
      tintColor: 'white',
    },
    nineteen: {
      value: 50,
      label: 'Nineteen',
      image: require('../../assests/ir/19.png'),
      tintColor: 'white',
    },
    twenty: {
      value: 51,
      label: 'Twenty',
      image: require('../../assests/ir/20.png'),
      tintColor: 'white',
    },
    twenty_one: {
      value: 52,
      label: 'Twenty One',
      image: require('../../assests/ir/21.png'),
      tintColor: 'white',
    },
    twenty_two: {
      value: 53,
      label: 'Twenty Two',
      image: require('../../assests/ir/22.png'),
      tintColor: 'white',
    },
    twenty_three: {
      value: 54,
      label: 'Twenty Three',
      image: require('../../assests/ir/23.png'),
      tintColor: 'white',
    },
    twenty_four: {
      value: 55,
      label: 'Twenty Four',
      image: require('../../assests/ir/24.png'),
      tintColor: 'white',
    },
    twenty_five: {
      value: 56,
      label: 'Twenty Five',
      image: require('../../assests/ir/25.png'),
      tintColor: 'white',
    },
    twenty_six: {
      value: 57,
      label: 'Twenty Six',
      image: require('../../assests/ir/26.png'),
      tintColor: 'white',
    },
    twenty_seven: {
      value: 58,
      label: 'Twenty Seven',
      image: require('../../assests/ir/27.png'),
      tintColor: 'white',
    },
    twenty_eight: {
      value: 59,
      label: 'Twenty Eight',
      image: require('../../assests/ir/28.png'),
      tintColor: 'white',
    },
    twenty_nine: {
      value: 60,
      label: 'Twenty Nine',
      image: require('../../assests/ir/29.png'),
      tintColor: 'white',
    },
    thirty: {
      value: 61,
      label: 'Thirty',
      image: require('../../assests/ir/30.png'),
      tintColor: 'white',
    },
    mode: {
      value: 62,
      label: 'Mode',
      image: require('../../assests/ir/source.png'),
      tintColor: 'white',
    },
    back: {
      value: 63,
      label: 'Back',
      tintColor: 'white',
      image: require('../../assests/ir/back.png'),
    },
    channel: {value: 64, label: 'Channel', tintColor: 'white', image: ''},
  };

export const ACRemoteButtons = {
    'Power On': RemoteButtons.power_on,
    'Power Off': RemoteButtons.power_off,
    Mode: RemoteButtons.mode,
    Swing: RemoteButtons.swing,
    Fan: RemoteButtons.fan,
    Turbo: RemoteButtons.turbo,
    Sixteen: RemoteButtons.sixteen,
    Seventeen: RemoteButtons.seventeen,
    Eighteen: RemoteButtons.eighteen,
    Nineteen: RemoteButtons.nineteen,
    Twenty: RemoteButtons.twenty,
    'Twenty one': RemoteButtons.twenty_one,
    'Twenty two': RemoteButtons.twenty_two,
    'Twenty three': RemoteButtons.twenty_three,
    'Twenty four': RemoteButtons.twenty_four,
    'Twenty five': RemoteButtons.twenty_five,
    'Twenty six': RemoteButtons.twenty_six,
    'Twenty seven': RemoteButtons.twenty_seven,
    'Twenty eight': RemoteButtons.twenty_eight,
    'Twenty nine': RemoteButtons.twenty_nine,
    Thirty: RemoteButtons.thirty,
  };

export const tempButton ={
    Sixteen: RemoteButtons.sixteen,
    Seventeen: RemoteButtons.seventeen,
    Eighteen: RemoteButtons.eighteen,
    Nineteen: RemoteButtons.nineteen,
    Twenty: RemoteButtons.twenty,
    'Twenty one': RemoteButtons.twenty_one,
    'Twenty two': RemoteButtons.twenty_two,
    'Twenty three': RemoteButtons.twenty_three,
    'Twenty four': RemoteButtons.twenty_four,
    'Twenty five': RemoteButtons.twenty_five,
    'Twenty six': RemoteButtons.twenty_six,
    'Twenty seven': RemoteButtons.twenty_seven,
    'Twenty eight': RemoteButtons.twenty_eight,
    'Twenty nine': RemoteButtons.twenty_nine,
    Thirty: RemoteButtons.thirty,
}